import { useEffect } from 'react';
import { getCookies, setCookie } from 'cookies-next';
import jwt, { JwtPayload } from 'jsonwebtoken';

import { getCompanyData } from 'utils/getCompanyData';

import { Home } from 'components/pages';
import { Layout } from 'components/templates';
import { api, getApiApplicants, apiV2 } from 'services/api';
import useStore from 'store';
import { ApplyTalentBankProvider } from 'contexts';
import { getVacanciesData } from 'utils/getVacanciesData';
import { googleForJobsListData } from 'helpers/seo';

const BASE_URL = process.env.HOST;

function Main({ company, statuses }) {
  const { setCompany, setStatus } = useStore(state => state.company);

  useEffect(() => {
    setCompany(company);
    localStorage.setItem('colorCompany', company?.colorCompany);
    localStorage.setItem('colorTextCompany', company?.colorTextCompany);
    localStorage.setItem('slugCompany', company?.slug);
    localStorage.setItem('companyUrl', company?.companyUrl);
  }, [company, setCompany]);

  useEffect(() => {
    setStatus(statuses.company);
  }, [statuses, setStatus]);

  return (
    <Layout>
      <ApplyTalentBankProvider>
        <Home />
      </ApplyTalentBankProvider>
    </Layout>
  );
}

export async function getServerSideProps(context) {
  // const features = await fetchFeatures(process.browser);
  const isApplicantsV3Enabled = useStore?.getState()?.featuresToggle?.isEnabled('applicantsV3');
  const apiAplicants = getApiApplicants(isApplicantsV3Enabled);

  const company = await getCompanyData(context);
  if (!company)
    return {
      redirect: {
        destination: BASE_URL,
        permanent: false,
      },
    };

  let statuses = {
    company: {
      talentBank: {},
    },
  } as {
    company: {
      talentBank: {
        status?: string;
      };
    };
  };
  const cookies = getCookies({ req: context.req, res: context.res, path: '/' });
  const auth = jwt.decode(cookies?.idToken) as JwtPayload;
  let tab, tabKey;

  try {
    const { redirectCurrentWindow } = cookies;
    tabKey = redirectCurrentWindow || '';
    tab = JSON.parse(cookies[tabKey] || '{}');
  } catch (err) {}

  try {
    if (auth?.email) {
      const { token: jToken, idToken } = cookies;
      const headers = isApplicantsV3Enabled
        ? {
            Authorization: `Bearer ${idToken || ''}`,
          }
        : { jToken };

      if (tab?.applyingTalentBase && tab?.origin !== 'login') {
        const { data: user } = await api.get('/user', {
          headers: {
            Authorization: idToken || '',
          },
        });

        if (!user?.name || !user?.email || !user?.cpf) {
          return {
            redirect: {
              destination: `${process.env.CANDIDATE_HOST_V3}/curriculo`,
              // destination: features.includes('newCurriculum')
              //   ? `${process.env.CANDIDATE_HOST_V3}/curriculo`
              //   : `${process.env.CANDIDATE_HOST}/curriculum`,
              permanent: false,
            },
          };
        } else {
          if (!tab?.ignoreApplications) {
            const { data: userTalentBases } = await apiV2.get('/applicant/my-talent-bases', {
              headers,
            });

            const hasApplyedCompany = userTalentBases?.data?.find(item => item.slug === company.slug);
            if (hasApplyedCompany) {
              statuses.company.talentBank = {
                status: 'has_applied',
              };
            }
          }

          if (statuses.company.talentBank?.status !== 'has_applied') {
            const { data: response } = await apiV2.post(
              '/applicant/apply-job',
              {
                referenceId: company.referenceId,
                slug: company.slug,
              },
              {
                headers,
              }
            );
            statuses.company.talentBank = {
              status: response?.code_request_gestao === 200 ? 'success' : 'error',
            };
          }
        }
      } else if (tab?.applyingTalentBase && tab?.origin === 'login') {
        const { data: user } = await api.get('/user', {
          headers: {
            Authorization: idToken || '',
          },
        });

        if (!user?.name || !user?.email || !user?.cpf) {
          return {
            redirect: {
              destination: `${process.env.CANDIDATE_HOST_V3}/curriculo`,
              permanent: false,
            },
          };
        }

        if (tab?.ignoreApplications === true && statuses?.company?.talentBank?.status !== 'has_applied') {
          const { data: response } = await apiV2.post(
            '/applicant/apply-job',
            {
              referenceId: company.referenceId,
              slug: company.slug,
            },
            {
              headers,
            }
          );

          statuses.company.talentBank = {
            status: response?.code_request_gestao === 200 ? 'success' : 'error',
          };
        }

        if (!tab?.ignoreApplications) {
          const { data: userTalentBases } = await apiV2.get('/applicant/my-talent-bases', {
            headers,
          });

          const hasAppliedCompany = userTalentBases?.data?.find(item => item.slug === company.slug);

          const status = hasAppliedCompany ? 'has_applied' : 'update';

          statuses.company.talentBank = { status };
        }
      }
      setCookie(tabKey, JSON.stringify({ ...tab, applyingTalentBase: false }), {
        req: context.req,
        res: context.res,
        maxAge: 60 * 1 * 24,
        domain: process.env.COOKIE_DOMAIN,
      });
    }
  } catch (err) {
    if (tab?.applyingTalentBase) {
      statuses.company.talentBank = {
        status: 'error',
      };

      if (auth?.email && err?.response?.status === 401)
        return {
          redirect: {
            destination: `${process.env.CANDIDATE_HOST_V3}/profiler`,
            permanent: false,
          },
        };
      else if (cookies?.redirectCurrentWindow)
        setCookie(cookies.redirectCurrentWindow, JSON.stringify({ applyingTalentBase: false }), {
          req: context.req,
          res: context.res,
          maxAge: 60 * 1 * 24,
          domain: process.env.COOKIE_DOMAIN,
        });
    }
  }

  return {
    props: { company, statuses, googleForJobsData: null },
  };
}

export default Main;
